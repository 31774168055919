import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Placeholder from '../../../core/Components/Placeholder';

const ThirtyThreeSplitContainer = ({
  rendering,
  noPadding = false,
  animation = false,
}) => {
  const holderLeft = (
    <Placeholder name="content-33-split-left" rendering={rendering} />
  );
  const holderMiddle = (
    <Placeholder name="content-33-split-middle" rendering={rendering} />
  );
  const holderRight = (
    <Placeholder name="content-33-split-right" rendering={rendering} />
  );

  return (
    <div className={`sl ${noPadding ? '' : 'l-padding'} is-33-split`}>
      <div className="sl-list has-3-items">
        <div className="sl-item">
          {animation ? (
            <ScrollAnimation
              animateIn="toFadeInUp"
              animateOnce
              delay={100}
              duration={0.5}
            >
              {holderLeft}
            </ScrollAnimation>
          ) : (
            holderLeft
          )}
        </div>
        <div className="sl-item">
          {animation ? (
            <ScrollAnimation
              animateIn="toFadeInUp"
              animateOnce
              delay={150}
              duration={0.5}
            >
              {holderMiddle}
            </ScrollAnimation>
          ) : (
            holderMiddle
          )}
        </div>
        <div className="sl-item">
          {animation ? (
            <ScrollAnimation
              animateIn="toFadeInUp"
              animateOnce
              delay={200}
              duration={0.5}
            >
              {holderRight}
            </ScrollAnimation>
          ) : (
            holderRight
          )}
        </div>
      </div>
    </div>
  );
};

export default ThirtyThreeSplitContainer;
